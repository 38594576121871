import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ActionDialog, Alert, Copy, Field, Input, Modal, Select } from '@hexure/ui';
import { mdiFileDownloadOutline, mdiEmailOutline, mdiForumOutline, mdiChevronRight, mdiCheckboxMarkedCircleOutline, mdiOpenInNew } from '@mdi/js';

import ActionCard from 'shared/ActionCard';
import { SA_BASE_URL } from 'config/App';

import { ThemeContext } from 'shared/ThemeContext';

import ApiUtils from 'utils/AnnuitiesApi';
import FormUtils from 'utils/Form';
import AlqApi from '../../utils/AlqApi';

const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 40px;
`;

const Layouts = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2px;
  justify-items: center;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  cursor: pointer;
  width: 100%;
  max-width: 400px;
`;

const PdfPreview = styled.div`
  width: 400px;
  height: 280px;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => (props.$isSelected ? props.$color : '#fff')};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${props => props.$image});
  position: relative;
`;

const InfoContent = styled.div`
  width: 800px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Form = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`;

const Column = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FieldGroup = styled.div`
  display: flex;
  padding: 18px 18px 0;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #fcfcfc;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

const PdfActions = ({ agent = {}, client = {}, onClose, selectedQuotes, mode = 'comparison', type = 'text', activity = '' }) => {
  const { color } = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState({
    delivery_method: type,
    template: activity === 'alq' ? 'default' : 'demo',
    agent_email: agent?.email || '',
    agent_first_name: agent?.first_name || '',
    agent_last_name: agent?.last_name || '',
    agent_phone: agent?.phone || '',
    agent_license: agent?.license || '',
    first_name: client?.first_name || '',
    last_name: client?.last_name || '',
    quote_guids: selectedQuotes,
    phone: '',
    email: '',
    subject: activity === 'alq' ? 'Yor Agency Quote' : 'Your Annuities Quote',
    message:
      activity === 'alq'
        ? 'Thank you for your interest in a life insurance policy! Take a look at your personalized insurance quotes.'
        : 'Thank you for your interest! Take a look at your personalized annuities quote.'
  });
  const [page, setPage] = useState(mode === 'action' ? 'information' : 'delivery_method');
  const [pdf_url, setPdfUrl] = useState(null);
  const titles = {
    delivery_method: 'Select a Delivery Method',
    template: 'Select a PDF Layout',
    information: 'Enter Information'
  };
  const setDeliveryMethod = delivery_method => {
    setData({
      ...data,
      delivery_method
    });
  };
  const setLayout = template => {
    setData({
      ...data,
      template
    });
  };
  const handleInputChange = (name, e) => {
    setData({
      ...data,
      [name]: e.target.value
    });
  };
  const handlePdfTemplate = (field, selectedValue) => {
    setData(prevData => ({
      ...prevData,
      [field]: selectedValue
    }));
  };
  const handleSubmit = () => {
    const new_errors = [];
    const { phone, email, subject, message, delivery_method, first_name, last_name, quote_guids, ...payload } = data;

    if (delivery_method === 'email') {
      if (!FormUtils._validateEmail(payload.agent_email)) {
        new_errors.push('agent_email');
      }

      if (!FormUtils._validateEmail(email || payload.agent_email)) {
        new_errors.push('email');
      }

      if (!subject) {
        new_errors.push('subject');
      }

      if (!message) {
        new_errors.push('message');
      }
    }

    if (delivery_method === 'text') {
      if (!FormUtils._validatePhone(phone || payload.agent_phone)) {
        new_errors.push('phone');
      }
      if (!message) {
        new_errors.push('message');
      }
    }

    if (new_errors.length) {
      setErrors(new_errors);
    } else {
      setLoading(true);

      if (delivery_method === 'email') {
        payload.from = payload.agent_email;
        payload.to = email;
        payload.subject = subject;
        payload.message = message;
      }

      if (payload.delivery_method === 'text') {
        payload.phone_number = phone;
        payload.message = message;
      }

      if (activity === 'alq') {
        payload.agent_license_number = payload.agent_license;
        payload.agent_phone_number = payload.agent_phone;
        payload.agent_name = payload.agent_first_name + ' ' + payload.agent_last_name;
        delete payload.agent_first_name;
        delete payload.agent_last_name;
        delete payload.agent_phone;
        delete payload.agent_license;
      }

      (activity === 'alq' ? AlqApi.generateQuotesPdf : ApiUtils.generateQuotesPdf)({
        ...payload,
        quote_guids: mode === 'action' && activity !== 'alq' ? [quote_guids.id] : activity === 'alq' && mode === 'comparison' ? quote_guids : activity === 'alq' ? [quote_guids.guid] : quote_guids,
        client_name: `${first_name || payload.agent_first_name} ${last_name || payload.agent_last_name}`,
        pdf_action: delivery_method,
        phone_number: data?.phone || payload.agent_phone || '',
        message: data?.message || '',
        show_agent_info: true
      })
        .then(({ data }) => {
          setLoading(false);
          setPdfUrl(data.url);
          setPage('success');
        })
        .catch(() => {
          setErrors(['api_error']);
          setTimeout(() => {
            setErrors([]);
          }, 3000);
        });
    }
  };
  const buttons = {
    delivery_method:
      mode === 'comparison'
        ? {
            secondaryButton: {
              children: 'Cancel',
              onClick: onClose
            },
            primaryButton: {
              children: 'Next',
              icon: mdiChevronRight,
              onClick: setPage.bind(null, 'template')
            }
          }
        : {},
    template:
      mode === 'comparison'
        ? {
            secondaryButton: {
              children: 'Back',
              onClick: setPage.bind(null, 'delivery_method')
            },
            primaryButton: {
              children: 'Next',
              icon: mdiChevronRight,
              onClick: setPage.bind(null, 'information')
            }
          }
        : {},
    information: {
      secondaryButton: {
        children: 'Back',
        disabled: loading,
        onClick: mode === 'action' ? onClose : setPage.bind(null, 'template')
      },
      primaryButton: {
        children: data.delivery_method === 'download' ? `${loading ? 'Creating PDF...' : 'Create PDF'}` : `${loading ? 'Sending...' : 'Send'}`,
        icon: mdiCheckboxMarkedCircleOutline,
        loading,
        onClick: handleSubmit
      }
    }
  };

  return page === 'success' ? (
    <ActionDialog
      description={
        data.delivery_method === 'download'
          ? 'Your quote comparison PDF is ready. Click the button below to download it.'
          : 'Your quote comparison PDF has been sent. Click the button below to view the PDF that was sent.'
      }
      primaryButton={{
        children: 'View PDF',
        icon: mdiOpenInNew,
        onClick: () => window.open(pdf_url)
      }}
      secondaryButton={{
        children: 'Back to Results',
        onClick: onClose
      }}
      title='Success!'
    />
  ) : (
    <Modal onClose={onClose} title={mode === 'action' ? 'Enter Information' : titles[mode === 'action' ? 'information' : page]} {...buttons[page]} id='actions-modal'>
      {page === 'delivery_method' && mode !== 'action' ? (
        <Actions>
          <ActionCard icon={mdiForumOutline} isSelected={data.delivery_method === 'text'} onClick={setDeliveryMethod.bind(null, 'text')} id='actions-text-pdf'>
            Text PDF
          </ActionCard>
          <ActionCard icon={mdiEmailOutline} isSelected={data.delivery_method === 'email'} onClick={setDeliveryMethod.bind(null, 'email')} id='actions-email-pdf'>
            Email PDF
          </ActionCard>
          <ActionCard icon={mdiFileDownloadOutline} isSelected={data.delivery_method === 'download'} onClick={setDeliveryMethod.bind(null, 'download')} id='actions-download-pdf'>
            Download PDF
          </ActionCard>
        </Actions>
      ) : null}

      {page === 'template' && mode !== 'action' ? (
        <Layouts>
          <Layout $color={color} onClick={setLayout.bind(null, activity === 'alq' ? 'default' : 'demo')} id='actions-demo-layout-report'>
            <Copy type='bold'>{activity === 'alq' ? 'Detailed Comparison' : 'Income Comparison Report'}</Copy>
            <PdfPreview
              $color={color}
              $image={activity === 'alq' ? `${SA_BASE_URL}/ixn/images/classic.png` : 'https://ixntestsa001.blob.core.windows.net/ixn/images/standard.png'}
              $isSelected={activity === 'alq' ? data.template === 'default' : data.template === 'demo'}
              id='actions-income-comparison-report'
            />
          </Layout>
          <Layout $color={color} onClick={setLayout.bind(null, activity === 'alq' ? 'clementine_condensed' : 'annuity_quoter_features')} id='actions-features-layout-report'>
            <Copy type='bold'>{activity === 'alq' ? 'Condensed Premium Comparison' : 'Features Report'}</Copy>
            <PdfPreview
              $color={color}
              $image={activity === 'alq' ? `${SA_BASE_URL}/ixn/images/clementine_condensed.png` : 'https://ixntestsa001.blob.core.windows.net/ixn/images/feature-comparison.PNG'}
              $isSelected={activity === 'alq' ? data.template === 'clementine_condensed' : data.template === 'annuity_quoter_features'}
              id='actions-features-report'
            />
          </Layout>
          <Layout $color={color} onClick={setLayout.bind(null, activity === 'alq' ? 'clementine_by_premium' : 'annuity_quoter_bonus')} id='actions-bonus-layout-report'>
            <Copy type='bold'>{activity === 'alq' ? 'Premium Comparison' : 'Bonus Comparison Report'}</Copy>
            <PdfPreview
              $color={color}
              $image={activity === 'alq' ? `${SA_BASE_URL}/ixn/images/clementine_by_premium.png` : 'https://ixntestsa001.blob.core.windows.net/ixn/images/bonus-comparison.PNG'}
              $isSelected={activity === 'alq' ? data.template === 'clementine_by_premium' : data.template === 'annuity_quoter_bonus'}
              id='actions-bonus-comparison-report'
            />
          </Layout>
          <Layout $color={color} onClick={setLayout.bind(null, activity === 'alq' ? 'clementine_by_feature' : 'annuity_quoter_accumulation')} id='actions-accumulation-layout-report'>
            <Copy type='bold'>{activity === 'alq' ? 'Feature Comparison' : 'Accumulation Report'}</Copy>
            <PdfPreview
              $color={color}
              $image={activity === 'alq' ? `${SA_BASE_URL}/ixn/images/clementine_by_feature.png` : 'https://ixntestsa001.blob.core.windows.net/ixn/images/accumulation-comparison-report.png'}
              $isSelected={activity === 'alq' ? data.template === 'clementine_by_feature' : data.template === 'annuity_quoter_accumulation'}
              id='actions-accumulation-comparison-report'
            />
          </Layout>
        </Layouts>
      ) : null}

      {page === 'information' ? (
        <InfoContent>
          {errors.includes('api_error') ? (
            <Alert
              description='An error occurred while trying to generate the PDF. If it continues to happen, please contact support.'
              title='Something went wrong'
              type='error'
              id='actions-error-message'
            />
          ) : null}
          <Form>
            <Column>
              <Copy type='bold'>Agent Information</Copy>
              <FieldGroup>
                <Row>
                  <Field label='First Name' style={{ width: 120 }} id='actions-info-agent-first-name'>
                    <Input onChange={handleInputChange.bind(null, 'agent_first_name')} readOnly={loading} value={data.agent_first_name} id='actions-info-agent-first-name-input' />
                  </Field>
                  <Field label='Last Name' style={{ flex: 1 }} id='actions-info-agent-last-name'>
                    <Input onChange={handleInputChange.bind(null, 'agent_last_name')} readOnly={loading} value={data.agent_last_name} id='actions-info-agent-last-name-input' />
                  </Field>
                </Row>
                <Field label='Email' required={data.delivery_method === 'email'} style={{ width: '100%' }} id='actions-info-agent-email'>
                  <Input
                    invalid={errors.includes('agent_email')}
                    onChange={handleInputChange.bind(null, 'agent_email')}
                    readOnly={loading}
                    value={data.agent_email}
                    id='actions-info-agent-email-input'
                  />
                </Field>
                <Field label='Phone' style={{ width: '100%' }} id='actions-info-agent-phone'>
                  <Input format='phone' onChange={handleInputChange.bind(null, 'agent_phone')} readOnly={loading} value={data.agent_phone} id='actions-info-agent-phone-input' />
                </Field>
                <Field label='License Number' style={{ width: '100%' }} id='actions-info-agent-license'>
                  <Input onChange={handleInputChange.bind(null, 'agent_license')} readOnly={loading} value={data.agent_license} id='actions-info-agent-license-input' />
                </Field>
              </FieldGroup>
            </Column>
            <Column>
              <Copy type='bold'>Recipient Information</Copy>
              <FieldGroup>
                <Row>
                  <Field label='First Name' style={{ width: 120 }} id='actions-recipient-client-first-name'>
                    <Input onChange={handleInputChange.bind(null, 'first_name')} readOnly={loading} value={data.first_name} id='actions-recipient-client-first-name-input' />
                  </Field>
                  <Field label='Last Name' style={{ flex: 1 }} id='actions-recipient-client-last-name'>
                    <Input onChange={handleInputChange.bind(null, 'last_name')} readOnly={loading} value={data.last_name} id='actions-recipient-client-last-name-input' />
                  </Field>
                </Row>
                {mode !== 'action' && activity !== 'alq' ? (
                  <Field label='Select Pdf Template' required={true} style={{ width: '100%' }} id='actions-action-pdf-template'>
                    <Select
                      onChange={e => handlePdfTemplate('template', e.target.value)}
                      options={[
                        { label: 'demo', value: 'demo' },
                        { label: 'annuity_quoter_accumulation', value: 'annuity_quoter_accumulation' },
                        { label: 'annuity_quoter_pages', value: 'annuity_quoter_pages' }
                      ]}
                      required={true}
                      value={data.template}
                      id='actions-action-pdf-template-select'
                    />
                  </Field>
                ) : null}
                {(data.delivery_method === 'email' && mode === 'comparison') || (mode === 'action' && type === 'email') ? (
                  <>
                    <Field label='Email' required={true} style={{ width: '100%' }} id='actions-email-label'>
                      <Input invalid={errors.includes('email')} onChange={handleInputChange.bind(null, 'email')} readOnly={loading} value={data.email} id='actions-email-input' />
                    </Field>
                    <Field label='Subject' required={true} style={{ width: '100%' }} id='actions-subject-label'>
                      <Input invalid={errors.includes('subject')} onChange={handleInputChange.bind(null, 'subject')} readOnly={loading} value={data.subject} id='actions-subject-input' />
                    </Field>
                    <Field label='Message' required={true} style={{ width: '100%' }} id='actions-message-label'>
                      <Input
                        height={140}
                        invalid={errors.includes('message')}
                        onChange={handleInputChange.bind(null, 'message')}
                        readOnly={loading}
                        style={{ width: '100%' }}
                        type='textarea'
                        value={data.message}
                        id='actions-message-input'
                      />
                    </Field>
                  </>
                ) : null}

                {(data.delivery_method === 'text' && mode === 'comparison') || (mode === 'action' && type === 'text') ? (
                  <>
                    <Field label='Phone' required={true} style={{ width: '100%' }} id='actions-text-phone-label'>
                      <Input format='phone' invalid={errors.includes('phone')} onChange={handleInputChange.bind(null, 'phone')} readOnly={loading} value={data.phone} id='actions-text-phone-input' />
                    </Field>
                    <Field label='Message' required={true} style={{ width: '100%' }} id='actions-text-message-label'>
                      <Input
                        height={140}
                        invalid={errors.includes('message')}
                        onChange={handleInputChange.bind(null, 'message')}
                        readOnly={loading}
                        style={{ width: '100%' }}
                        type='textarea'
                        value={data.message}
                        id='actions-text-message-input'
                      />
                    </Field>
                  </>
                ) : null}
              </FieldGroup>
            </Column>
          </Form>
        </InfoContent>
      ) : null}
    </Modal>
  );
};

PdfActions.propTypes = {
  agent: PropTypes.object,
  client: PropTypes.object,
  solve: PropTypes.string,
  onClose: PropTypes.func,
  selectedQuotes: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
  mode: PropTypes.string,
  type: PropTypes.string,
  activity: PropTypes.string
};

export default PdfActions;
